var BLL = (function() {

  var options = {
    xhrUrl: '/app/submitEmail.php',
    errorElem: $('.js-error'),
    inputElem: $('.js-email'),
    honeyElem: $('.js-name'),
    submitElem: $('.js-submit'),
    formElem: $('.js-updates')
  };

  function init() {
    initClickHandlers();
  }

  function initClickHandlers() {
    options.formElem.on('submit', function(e) {
      e.preventDefault();

      resetErrors();

      var email = options.inputElem.val();
      var honey = options.honeyElem.val();

      submitEmail(email, honey)
        .done(function(data) {
          if (data.error) {
            showMessage(data.error, true);
          } else {
            // Success
            resetErrors();
            showMessage('Thank you for your interest.', false);
            options.submitElem.attr('disabled', 'disabled');
          }
        })
        .fail(function(xhr) {
          // Argument missing
          if (xhr.status == 403) {
            showMessage('Please enter your email address.', true);
          }
        });
    });
  }

  function resetErrors() {
    options.errorElem.removeClass('visible');
    options.inputElem.removeClass('error');
  }

  function showMessage(message, error) {
    options.errorElem.html(message);
    options.errorElem.addClass('visible');

    if (error) {
      options.inputElem.addClass('error');
    }

    options.inputElem.focus();
  }

  function submitEmail(email, honey) {
    if (honey != "") {
      return;
    }

    return $.ajax({
      url: options.xhrUrl,
      type: 'POST',
      dataType: 'JSON',
      data: {
        email: email
      }
    });
  }

  return {
    init: init
  };

})();

$(function() {
  BLL.init();
});